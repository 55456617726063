<template>
    <div v-resize="onResize" class="fill-height" ref="container">
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{
                    name: 'settingsCosts',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                Costs
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsCommercial',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                Commercial Terms
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsQuotesRanges',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                Ranges
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsImage',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                PDF Images
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsUnits',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                Units
            </v-tab>
            <v-tab
                :to="{
                    name: 'emailBodySettings',
                    params: {
                        setting,
                        emailBody,
                    },
                }"
            >
                Email Body
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsReasons',
                    params: {
                        setting,
                        commercialTerms: commercialTerms,
                    },
                }"
            >
                Lost Reasons
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsInform',
                    params: {
                        setting,
                    },
                }"
            >
                Reports
            </v-tab>
            <v-tab
                :to="{
                    name: 'settingsMilestones',
                    params: {
                        setting,
                    },
                }"
            >
                Milestones
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <span>Quotes</span>
                <v-spacer />
                <v-btn
                    v-if="this.$router.currentRoute.name == 'settingsUnits'"
                    @click="updateQuoteSettings"
                    class="mx-1"
                    depressed
                    :disabled="saveBtn"
                    rounded
                    color="success"
                    >Save</v-btn
                >
                <v-btn
                    v-if="
                        this.$router.currentRoute.name == 'settingsCommercial'
                    "
                    @click="uploadTerms"
                    class="mx-1"
                    depressed
                    :disabled="saveBtn"
                    rounded
                    color="success"
                    >Save</v-btn
                >
                <v-btn
                    v-if="this.$router.currentRoute.name == 'emailBodySettings'"
                    @click="uploadEmailBody"
                    class="mx-1"
                    depressed
                    :disabled="saveBtn"
                    rounded
                    color="success"
                    >Save</v-btn
                >
                <v-btn
                    v-if="this.$router.currentRoute.name == 'lostReason'"
                    @click="updateQuoteSettings"
                    class="mx-1"
                    depressed
                    :disabled="saveBtn"
                    rounded
                    color="success"
                    >Save</v-btn
                >
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0 pb-1">
                <v-divider />
            </v-card-subtitle>

            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'
//import { storage } from '@/services/firebase'
import { mapState, mapActions, mapMutations } from 'vuex'
export default {
    name: 'QuoteSettings',
    components: {},
    props: {
        setting: Object,
    },
    data() {
        return {
            height: 0,
            loading: false,
            error: null,
            errorMsg: null,
            newSetting: this.setting,
            costSettings: null,
            folderPdfImage: 'pdf_images',
            logSettings: null,
            generalSettings: null,
            commercialSettings: null,
            companyId: JSON.parse(localStorage.getItem('company')),
        }
    },
    updated() {
        this.onResize()
    },
    watch: {
        setting: function() {
            this.getTerms()
            this.getEmailBody()
        },
        pdfImages: function(newPdf) {
            const params = this.$router.currentRoute.params.pdfImages
            const route = this.$router.currentRoute.name
            if (!params) {
                if (
                    route == 'settingsCosts' ||
                    route == 'settingsCommercial' ||
                    route == 'settingsImage' ||
                    route == 'settingsUnits' ||
                    route == 'emailBodySettings'
                ) {
                    this.$router.currentRoute.params.pdfImages = newPdf
                    this.$router.currentRoute.params.commercialTerms = this.commercialTerms
                }
            }
        },
    },
    computed: {
        ...mapState(['saveBtn', 'pdfImages', 'commercialTerms', 'emailBody']),
    },
    mounted() {
        if (this.setting) {
            if (!this.pdfImages) {
                this.getTerms()
            }
            this.getEmailBody()
        }
    },
    methods: {
        ...mapActions([
            'quoteChangesAction',
            'addImagesAction',
            'addTermsAction',
        ]),
        ...mapMutations(['setEmailBody', 'setErrorItems']),
        updateQuoteSettings: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateSetting(id, this.setting)
                this.quoteChangesAction(true)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        uploadTerms: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateTerms(id, this.commercialTerms[0])
                await API.updateTerms(id, this.commercialTerms[1])
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        async uploadEmailBody() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const { id } = this.setting
                await API.updateEmailBody(id, this.emailBody[0])
                await API.updateEmailBody(id, this.emailBody[1])
                this.setEmailBody(this.emailBody)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        getTerms: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.setting) {
                    const {
                        data: { commercialTerms },
                    } = await API.getCommercialTerms(this.setting.id)
                    if (commercialTerms) {
                        this.addTermsAction(commercialTerms)
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        getEmailBody: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                if (this.setting) {
                    const {
                        data: { emailBody },
                    } = await API.getEmailBody(this.setting.id)
                    if (emailBody) {
                        this.setEmailBody(emailBody)
                    }
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
        },
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
    },
}
</script>
