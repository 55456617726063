var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"}],ref:"container",staticClass:"fill-height"},[_c('v-tabs',{ref:"tabs",attrs:{"active-class":"white","background-color":"grey lighten-3","hide-slider":"","show-arrows":""}},[_c('v-tab',{attrs:{"to":{
                name: 'settingsCosts',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" Costs ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsCommercial',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" Commercial Terms ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsQuotesRanges',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" Ranges ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsImage',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" PDF Images ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsUnits',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" Units ")]),_c('v-tab',{attrs:{"to":{
                name: 'emailBodySettings',
                params: {
                    setting: _vm.setting,
                    emailBody: _vm.emailBody,
                },
            }}},[_vm._v(" Email Body ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsReasons',
                params: {
                    setting: _vm.setting,
                    commercialTerms: _vm.commercialTerms,
                },
            }}},[_vm._v(" Lost Reasons ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsInform',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" Reports ")]),_c('v-tab',{attrs:{"to":{
                name: 'settingsMilestones',
                params: {
                    setting: _vm.setting,
                },
            }}},[_vm._v(" Milestones ")])],1),_c('v-card',{attrs:{"loading":_vm.loading,"flat":""}},[_c('v-card-title',{ref:"title"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-btn',{attrs:{"fab":"","depressed":"","icon":""},on:{"click":_vm.show}},[_c('v-icon',{attrs:{"size":"36"}},[_vm._v(" mdi-chevron-left ")])],1):_vm._e(),_c('span',[_vm._v("Quotes")]),_c('v-spacer'),(this.$router.currentRoute.name == 'settingsUnits')?_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","disabled":_vm.saveBtn,"rounded":"","color":"success"},on:{"click":_vm.updateQuoteSettings}},[_vm._v("Save")]):_vm._e(),(
                    this.$router.currentRoute.name == 'settingsCommercial'
                )?_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","disabled":_vm.saveBtn,"rounded":"","color":"success"},on:{"click":_vm.uploadTerms}},[_vm._v("Save")]):_vm._e(),(this.$router.currentRoute.name == 'emailBodySettings')?_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","disabled":_vm.saveBtn,"rounded":"","color":"success"},on:{"click":_vm.uploadEmailBody}},[_vm._v("Save")]):_vm._e(),(this.$router.currentRoute.name == 'lostReason')?_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","disabled":_vm.saveBtn,"rounded":"","color":"success"},on:{"click":_vm.updateQuoteSettings}},[_vm._v("Save")]):_vm._e()],1),_c('v-card-subtitle',{ref:"subtitle",staticClass:"mt-0 pb-1"},[_c('v-divider')],1),_c('v-card-text',{staticClass:"overflow-y-auto",style:(`height: ${_vm.height}px`)},[_c('router-view')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }